<template>
  <div>
    <div class="inner-sm">
      <!-- Tab Section -->
      <section class="tab-section">
        <!-- Tab -->
        <div class="tab-wrap">
          <ul class="tab type3">
            <li class="on">
              {{$t('content.center.GangnamReservation.txt01')}}
            </li>
            <div class="tab-bar"></div>
          </ul>
        </div>
        <!-- // Tab -->
        <!-- Tab Contents -->
        <div class="tab-contents-wrap">
          <template v-if="resultType === 1">
          <div class="title-wrap">
            <p class="message">{{$t('content.center.GangnamReservation.txt02')}}</p>
          </div>
            <!-- Form -->
            <div class="form-container">
              <ValidationObserver ref="form">
              <form>
                <fieldset>
                  <legend>{{$t('content.center.GangnamReservation.txt02_1')}}강남센터 방문 예약 입력</legend>
                  <!--<div class="align-right"><span class="required-mark">필수항목</span>는 필수입력 항목입니다.</div>-->
                  <ul>
                    <li class="form-row">
                      <BaseLabelElement
                        :label="$t('content.center.GangnamReservation.txt03')"
                        for="visitDate"
                        required
                      >
                      </BaseLabelElement>
                      <ValidationProvider
                        :rules="{ required: true }"
                        class="input-container input-md"
                        :name="$t('content.center.GangnamReservation.txt03_1')"
                        v-slot="{ errors }"
                      >
                        <Datepicker
                          v-model="visitDate"
                          :class="{'error':errors[0]}"
                          :format="customFormat"
                          input-class="calendar hasDatepicker"
                          :disabledDates="disabledDates"
                          @selected="setVisitTimeValue"
                        ></Datepicker>
                        <span v-if="errors[0]" class="input-error-desc">{{errors[0]}}</span>
                      </ValidationProvider>
                      <ValidationProvider
                        :rules="{ required: true }"
                        :name="$t('content.center.GangnamReservation.txt03_2')"
                        class="input-container select-container input-md"
                        v-slot="{ errors }"
                      >
                        <div :class="{'error':errors[0]}">
                          <select v-model="visit_time" class="time" >
                            <option value="" >{{$t('content.center.GangnamReservation.txt03_3')}}</option>
                            <option value="14" :disabled="!isVisitTime[0]">14:00</option>
                            <option value="15" :disabled="!isVisitTime[1]">15:00</option>
                            <!-- <option value="16" :disabled="!isVisitTime[2]">16:00</option> -->
                          </select>
                        </div>
                        <span v-if="errors[0]" class="input-error-desc">{{errors[0]}}</span>
                      </ValidationProvider>
                    </li>
                    <li class="form-row">
                      <ValidationProvider
                        :rules="{ required: true }"
                        :name="$t('content.center.GangnamReservation.txt04')"
                        v-slot="{ errors }"
                      >
                        <BaseInput
                          ref="visitor_name"
                          v-model="visitor_name"
                          :class="{'error':errors[0]}"
                          :label="$t('content.center.GangnamReservation.txt04')"
                          :placeholder="$t('content.center.GangnamReservation.txt04_1')"
                          required
                        />
                        <span v-if="errors[0]" class="input-error-desc">{{errors[0]}}</span>
                      </ValidationProvider>
                    </li>
                    <li class="form-row">
                      <ValidationProvider
                        :rules="{ required: true }"
                        :name="$t('content.center.GangnamReservation.txt05')"
                        v-slot="{ errors }"
                      >
                        <BaseInput
                          v-model="visitor_company"
                          :class="{'error':errors[0]}"
                          :label="$t('content.center.GangnamReservation.txt05')"
                          :placeholder="$t('content.center.GangnamReservation.txt05_1')"
                          required
                        />
                        <span v-if="errors[0]" class="input-error-desc">{{errors[0]}}</span>
                      </ValidationProvider>
                    </li>
                    <li class="form-row">
                      <ValidationProvider
                        :rules="{ required: true }"
                        :name="$t('content.center.GangnamReservation.txt06')"
                        v-slot="{ errors }"
                      >
                      <BaseLabelElement
                        :label="$t('content.center.GangnamReservation.txt06')"
                        for="codeVisitorCount"
                        required />
                        <div class="input-container select-container" :class="{'error':errors[0]}">
                          <select
                            v-model="code_visitor_count"
                            id="codeVisitorCount"
                            :class="{'error':errors[0]}"
                            name="count"
                          >
                            <option value="" disabled selected>
                              {{$t('content.center.GangnamReservation.txt06_1')}}
                            </option>
                            <!-- <option value="1">2인</option> -->
                            <!-- <option value="7">{{$t('content.center.GangnamReservation.txt06_2')}}</option> -->
                            <option value="1">{{$t('content.center.GangnamReservation.txt06_3')}}</option>
                            <option value="2">{{$t('content.center.GangnamReservation.txt06_4')}}</option>
                            <option value="3">{{$t('content.center.GangnamReservation.txt06_5')}}</option>
                            <option value="4">{{$t('content.center.GangnamReservation.txt06_6')}}</option>
                            <option value="5">{{$t('content.center.GangnamReservation.txt06_7')}}</option>
                            <option value="6">{{$t('content.center.GangnamReservation.txt06_8')}}</option>
                          </select>
                        </div>
                        <span v-if="errors[0]" class="input-error-desc">{{errors[0]}}</span>
                      </ValidationProvider>
                    </li>
                    <li class="form-row">
                      <ValidationProvider
                        :rules="{ required: true, phone: true  }"
                        :name="$t('content.center.GangnamReservation.txt07')"
                        v-slot="{ errors }"
                      >
                        <TelInput
                          v-model="visitor_phone"
                          :label="$t('content.center.GangnamReservation.txt07')"
                          :placeholder="$t('content.center.GangnamReservation.txt07_1')"
                          :class="{'error':errors[0]}"
                          required
                          maxlength="13"
                        />
                        <span v-if="errors[0]" class="input-error-desc">{{errors[0]}}</span>
                      </ValidationProvider>
                    </li>
                    <li class="form-row">
                      <ValidationProvider
                        :rules="{ required: true, email: true }"
                        :name="$t('content.center.GangnamReservation.txt08')"
                        v-slot="{ errors }"
                      >
                        <BaseInput
                          v-model="visitor_email"
                          :class="{'error':errors[0]}"
                          :label="$t('content.center.GangnamReservation.txt08')"
                          :placeholder="$t('content.center.GangnamReservation.txt08_1')"
                          required
                        />
                        <span v-if="errors[0]" class="input-error-desc">{{errors[0]}}</span>
                      </ValidationProvider>
                    </li>
                  </ul>
                  <div class="checkbox-container">
                    <BaseCheckbox
                      v-model="agreed"
                    >{{$t('content.center.GangnamReservation.txt09')}}</BaseCheckbox>
                  </div>
                  <!-- 이용동의 클릭시 -->
                  <div v-show="agreed" class="terms-container">
                      <ol>
                      <li v-html="$t('content.center.GangnamReservation.txt10')"></li>
                      <li v-html="$t('content.center.GangnamReservation.txt11')"></li>
                      <li v-html="$t('content.center.GangnamReservation.txt12')"></li>
                      </ol>
                  </div>
                  <!-- //이용동의 클릭시 -->
                </fieldset>
              </form>
              </ValidationObserver>
            </div>
            <!-- // Form -->
            <!-- 버튼 -->
            <div class="btn-wrap">
              <a href="#" @click="goBack" class="back">Back</a>
              <button
                class="btn-basic"
                @click="reserve"
                :disabled="!agreed"
              >{{$t('content.center.GangnamReservation.txt13')}}
              </button>
            </div>
            <!-- // 버튼 -->
          </template>

          <template v-else-if="resultType === 2">
            <!-- 방문예약접수완료 -->
            <div class="title-wrap">
              <p class="message" v-html="$t('content.center.GangnamReservation.txt14')"></p>
            </div>
            <div class="btn-wrap">
              <button
                class="btn-basic"
                @click="goToReservation"
              >{{$t('content.common.button.btn04')}}
              </button>
            </div>
            <!-- //방문예약접수완료 -->
          </template>

          <template v-else>
            <!-- 방문예약불가 -->
            <div class="title-wrap">
              <p class="message" v-html="$t('content.center.GangnamReservation.txt15')"></p>
            </div>
            <div class="btn-wrap">
              <button
                class="btn-basic"
                @click="goToRefresh"
              >{{$t('content.common.button.btn04')}}
              </button>
            </div>
            <!-- //방문예약불가 -->
          </template>
        </div>
        <!-- // Tab Contents -->
      </section>
      <!-- // Tab Section-->
    </div>
  </div>
</template>

<script>
import { APIs, CenterGangnamAPIs } from '@/common/portalApi';
import { mixin as mixinHelperUtils, mixinMotion } from '@/common/helperUtils';
import BaseLabelElement from '@/components/base/BaseLabelElement.vue';
import BaseCheckbox from '@/components/base/BaseCheckbox.vue';
import BaseInput from '@/components/base/BaseInput.vue';
import TelInput from '@/components/base/TelInput.vue';
import Datepicker from '@sum.cumo/vue-datepicker';
import '@sum.cumo/vue-datepicker/dist/vuejs-datepicker.css';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'GangnamReservation',
  mixins: [mixinHelperUtils, mixinMotion],
  components: {
    Datepicker,
    BaseLabelElement,
    BaseCheckbox,
    BaseInput,
    TelInput,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      holidays: [],
      resultType: 1,
      disabledDates: undefined,
      visitDate: undefined,
      visit_time: '',
      visitor_name: undefined,
      visitor_company: undefined,
      code_visitor_count: '',
      visitor_phone: undefined,
      visitor_email: undefined,
      agreed: false,
      isVisitTime: [!0, !0, !0],
    };
  },
  created() {
    const dDate = this.getDisabledDate();
    this.disabledDates = {
      to: dDate.subtract(1, 'day').toDate(),
      from: dDate.add(1, 'M').toDate(),
      days: [6, 5, 4, 3, 2, 1, 0],
    };

    this.setDisabledDate();
  },
  methods: {
    setVisitTimeValue(selectedDay) {
      this.visitDate = this.customFormat(selectedDay);
      this.visit_time = '';
      const startDateTime = this.$moment(selectedDay).format('YYYY-MM-DD+00:00:00');
      const endDateTime = this.$moment(selectedDay).format('YYYY-MM-DD+23:59:59');

      this.externalApiClient({
        method: 'get',
        url: CenterGangnamAPIs.VISIT_RESERVATION_SEARCH,
        params: {
          startDateTime,
          endDateTime,
        },
      }).then(({ data, status }) => {
        if (status === 200) {
          this.isVisitTime = [!0, !0, !0];
          // eslint-disable-next-line array-callback-return
          data.reduce((a, day) => {
            if (this.$moment(day.visitDateTime).format('HH') === '14' && day.visitCount >= 2) {
              this.isVisitTime[0] = false;
            }
            if (this.$moment(day.visitDateTime).format('HH') === '15' && day.visitCount >= 2) {
              this.isVisitTime[1] = false;
            }
            if (this.$moment(day.visitDateTime).format('HH') === '16' && day.visitCount >= 2) {
              this.isVisitTime[2] = false;
            }
          }, []);
        }
      });
    },
    setDisabledDate() {
      this.portalApiClient({
        url: APIs.EVENTVISIT_LIST,
      }).then(({ status, data }) => {
        if (status === 200) {
          this.disabledDates.days = [6, 0];
          this.disabledDates.dates = data.reduce((allDays, day) => {
            // eslint-disable-next-line no-underscore-dangle
            allDays.push(this.$moment(day.visit_limit_date)._d);
            return allDays;
          }, []);
        }
      });
    },
    getDisabledDate() {
      const self = this;
      let moment = this.$moment();
      // recursive
      // eslint-disable-next-line consistent-return
      const validDate = () => {
        let valided = false;
        // case1 check saturday, sunday
        self.holidays.some((element) => {
          const bool = moment.format('YYYYMMDD') === self.$moment(element).format('YYYYMMDD');
          if (bool) {
            moment.add(1, 'day');
            valided = true;
          }
          return bool;
        });

        // case2 check holiday
        const day = moment.day();
        if (day === 6) {
          moment = moment.add(2, 'day');
          valided = true;
        } else if (day === 0) {
          moment = moment.add(1, 'day');
          valided = true;
        }

        if (valided) {
          return validDate();
        }
      };

      if (moment.hour() > 13) {
        moment.add(1, 'day');
      }
      validDate();

      return moment;
    },
    customFormat(date) {
      this.visitDate = this.$moment(date).format('YYYY-MM-DD');
      return this.visitDate;
    },
    reserve() {
      this.validateForm(this.$refs.form).then(() => {
        const startDateTime = `${this.visitDate}+${this.visit_time}:00:00`;
        const endDateTime = `${this.visitDate}+${this.visit_time}:59:59`;
        this.externalApiClient({
          method: 'get',
          url: CenterGangnamAPIs.VISIT_RESERVATION_SEARCH,
          params: {
            startDateTime,
            endDateTime,
          },
        }).then(({ data }) => {
          if (data.length === 0 || data[0].visitCount < 2) {
            this.externalApiClient({
              url: CenterGangnamAPIs.VISIT_RESERVATION,
              data: {
                visitDateTime: `${this.visitDate} ${this.visit_time}:00:00`,
                visitorName: this.visitor_name,
                visitorCompanyName: this.visitor_company,
                visitorCount: this.code_visitor_count,
                visitorPhone: this.visitor_phone.replace(/-/gi, ''),
                visitorEmail: this.visitor_email,
              },
            }).then(() => {
              this.resultType = 2;
            });
          } else {
            this.resultType = 3;
          }
        });
      });
    },
    goToReservation() {
      if (this.$route.path === '/center/gangnam/reservation') {
        this.$router.push({ name: 'GangnamIntro' });
      } else {
        window.parent.location.href = `${process.env.VUE_APP_PORTAL}/intro`;
      }
    },
    goToRefresh() {
      this.resultType = 1;
    },
    goBack(event) {
      event.preventDefault();
      this.showConfirm(this.$t('content.center.GangnamReservation.txt18'), () => {
        this.$router.go(-1);
      });
    },
  },
};
</script>
<style>
.form-row .input-container {vertical-align: top;}
.select-container select{text-indent: 11px;}
</style>
